import React, { useEffect, useState } from 'react';
import auth0 from 'auth0-js';
import jwtDecode from 'jwt-decode';

const Callback = () => {

  let qsvalue = null;
  if (typeof window !== 'undefined') {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    const hash = localStorage.getItem("hash")

    qsvalue = hash ? params.returnUrl + hash : params.returnUrl;

    if ((qsvalue != "") && (qsvalue != "null") && (qsvalue != null) && (typeof qsvalue != "undefined")) {

      //window.location.href = qsvalue;
    }
    else {
      qsvalue = '/members';
    }
  }

  if (typeof window !== 'undefined') {
    window.InitBraze();
  }

  //console.log(qsvalue)

  const domain = process.env.AUTH0_DOMAIN;
  const clientID = process.env.AUTH0_CLIENTID;

  const webAuth = new auth0.WebAuth({
    domain: domain,
    clientID: clientID,
    redirectUri: qsvalue,
    audience: 'https://auth.boringmoney.co.uk/userinfo',
    responseType: 'token id_token',
    scope: 'openid profile email'
  });

  useEffect(() => {

    const BrazeStuff = async (BrazeID) => {

      try {
        //make sure its initialised
        await window.InitBraze();
        console.log("Callback Init Braze")
      } catch (e) {
        console.log("Callback Init Braze err")
      }

      try {
        //make sure any stored braze data is removed
        //window.braze.wipeData();
        console.log("Callback wipe Braze")
      } catch (e) {
        console.log("Callback wipe Braze err")
      }

      try {
        //set the braze user
        window.brazeSetUser(BrazeID)
        console.log("Callback user Braze")
      } catch (e) {
        console.log("Callback user Braze err")
      }

      try {
        //set gen mkting if it applies
        window.brazeGenMkting()
        console.log("Callback gen Braze")
      } catch (e) {

        console.log("Callback gen Braze err")
      }


      try {
        //add to any stored subgroup
        window.brazeAddToSubGroup()
        console.log("Callback brazeAddToSubGroup")
      } catch (e) {
        console.log("Callback brazeAddToSubGroup err")
      }

      try {
        //set custom attr if the user has signed in via a gate or widget
        window.brazeCustomAttr();
        console.log("Callback brazeCustomAttr")
      } catch (e) {
        console.log("Callback brazeCustomAttr err")
      }

      try {
        //custom from to Braze
        window.customFormToBraze();
        console.log("Callback customFormToBraze")
      } catch (e) {
        console.log("Callback customFormToBraze err")
      }

      try {
        //Email_Submission to Braze
        window.brazeEmailSubmitEvent();
        console.log("Callback brazeEmailSubmitEvent")
      } catch (e) {
        console.log("Callback brazeEmailSubmitEvent err")
      }
    }

    const checkSession = () => {
      //console.log("check sessiopn in callback.js firing")
      webAuth.checkSession({}, (err, authResult) => {
        if (err) {
          console.error(err);
          // If there's an error, redirect the user to the login page
          window.location.href = '/login';
        } else {
          // Set the tokens and user profile in localStorage
          localStorage.setItem('access_token', authResult.accessToken);
          localStorage.setItem('id_token', authResult.idToken);

          const tok = localStorage.getItem('id_token');
          const tokenData = jwtDecode(tok);

          //console.log("setting profile on line 57 callback.js. value: ", tokenData.ProfileComplete)
          localStorage.setItem('profile', tokenData.ProfileComplete);
          //try {
            BrazeStuff(tokenData.BrazeID);
          //} catch (e) {
          //  console.log("BRAZE ERR",e)
          //}


          // Redirect the user to the account page
          //window.location.href = '/account';
          if (
            typeof window !== "undefined" && localStorage.getItem("profile") === false ||
            typeof window !== "undefined" && localStorage.getItem("profile") === "False" ||
            typeof window !== "undefined" && localStorage.getItem("profile") === "0" ||
            typeof window !== "undefined" && localStorage.getItem("profile") === "1" ||
            typeof window !== "undefined" && localStorage.getItem("profile") === "2"

          ) {
            window.location.href = "/complete-profile"
          } else if ((qsvalue != "") && (qsvalue != "null") && (qsvalue != null) && (typeof qsvalue != "undefined") && (qsvalue != undefined)) {
            //console.log("2", qsvalue)
            window.location.href = qsvalue;
          } else {
            window.location.href = '/members';
          }
        }
      });
    }

    const handleAuthentication = () => {

      webAuth.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          // Set the tokens and user profile in localStorage

          if (typeof window !== 'undefined') {
            localStorage.setItem('access_token', authResult.accessToken);
            localStorage.setItem('id_token', authResult.idToken);

            const tok = localStorage.getItem('id_token');
            const tokenData = jwtDecode(tok);

            //console.log("setting profile on line 103 callback.js. value: ", tokenData.ProfileComplete)
            localStorage.setItem('profile', tokenData.ProfileComplete);
            localStorage.setItem('BrazeID', tokenData.BrazeID);
            //try {
              BrazeStuff(tokenData.BrazeID);
           // } catch (e) {
              //console.log("BRAZE ERR", e)
            //}
          }

          window.location.hash = '';
          //window.location.href = '/account';

          if (
            typeof window !== "undefined" && localStorage.getItem("profile") === false ||
            typeof window !== "undefined" && localStorage.getItem("profile") === "False" ||
            typeof window !== "undefined" && localStorage.getItem("profile") === "0" ||
            typeof window !== "undefined" && localStorage.getItem("profile") === "1" ||
            typeof window !== "undefined" && localStorage.getItem("profile") === "2"
          ) {
           window.location.href = "/complete-profile"
          } else if ((qsvalue != "") && (qsvalue != "null") && (qsvalue != null) && (typeof qsvalue != "undefined")) {
            //console.log("2", qsvalue)
            window.location.href = qsvalue;
          } else{
            window.location.href = '/members';
          }


        } else if (err) {
          console.log("err", err)

          // If the hash fragment doesn't contain valid tokens, call checkSession to obtain a new authResult
          checkSession();
        }
      });


    }

    handleAuthentication();
  }, [webAuth]);

  if (typeof window !== 'undefined') {
    //set gen mkting if it applies
    try {
      //set gen mkting if it applies
      window.brazeGenMkting()
    } catch (e) {
      console.log("Callback gen Braze")
    }
  }
  return null
}

export default Callback
